import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { routes } from "../../../lib/routes";
import { TextSection } from "../../Shared/Layout/components/TextSection/TextSection";

export interface OrientedOnSolutionsSectionProps {
  className?: string;
}

const OrientedOnSolutionsSectionInner = (
  props: OrientedOnSolutionsSectionProps
) => {
  const { t } = useTranslation("translations");
  return (
    <TextSection
      className={props.className}
      title={t("solutionsOrientedBanner.title2")}
      description={t("solutionsOrientedBanner.text")}
      button={t("solutionsOrientedBanner.button")}
      buttonUrl={routes.services.to}
    />
  );
};

export const OrientedOnSolutionsSection = styled(
  OrientedOnSolutionsSectionInner
)``;
