import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Button } from "../../Shared/Button/Primary/Button";
import { Section } from "../../Shared/Layout/components/Section/Section";
import { FreeVideoItem } from "./components/FreeVideoItem";
import { useFreeVideos } from "./hooks/useFreeVideos";

export interface FreeVideosSectionProps {
  className?: string;
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const VISIBLE_VIDEOS = 4;

const FreeVideosSectionInner = (props: FreeVideosSectionProps) => {
  const { t } = useTranslation("translations");
  const [allVisible, setAllVisible] = useState(false);

  const allVideos = useFreeVideos();

  const videos = useMemo(() => {
    if (allVisible) {
      return allVideos;
    } else {
      return allVideos.slice(0, VISIBLE_VIDEOS);
    }
  }, [allVisible]);

  return (
    <Section>
      <Container>
        <Grid container className={props.className} wrap="wrap" spacing={4}>
          {videos.map((video, index) => (
            <Grid key={index} item sm={6}>
              <FreeVideoItem
                videoTitle={video.title}
                videoDescription={video.text}
                videoUrl={video.videoUrl}
              />
            </Grid>
          ))}
        </Grid>
        <Actions className="">
          {allVideos.length > VISIBLE_VIDEOS && (
            <Button onClick={() => setAllVisible(!allVisible)}>
              {allVisible ? "Zobraziť menej" : "Zobraziť viac"}
            </Button>
          )}
        </Actions>
      </Container>
    </Section>
  );
};

export const FreeVideosSection = styled(FreeVideosSectionInner)``;
