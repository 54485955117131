import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { TextSection } from "../../Shared/Layout/components/TextSection/TextSection";

export interface HelpfulContentSectionProps {
  className?: string;
}

const HelpfulContentSectionInner = (props: HelpfulContentSectionProps) => {
  const { t } = useTranslation("translations");
  return (
    <TextSection
      className={props.className}
      title={t("freeResourcesPage.helpfulContent.title")}
      description={t("freeResourcesPage.helpfulContent.description")}
    />
  );
};

export const HelpfulContentSection = styled(HelpfulContentSectionInner)``;
