import { graphql, useStaticQuery } from "gatsby";

export interface FreeVideo {
  title: string;
  text: string;
  videoUrl: string;
}

export const useFreeVideos = (): FreeVideo[] => {
  const {
    strapiFreeResources: { videos },
  } = useStaticQuery(graphql`
    query useFreeVideos {
      strapiFreeResources {
        videos {
          title
          videoUrl
          text
        }
      }
    }
  `);

  return videos;
};
