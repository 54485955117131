import React from "react";
import { FreeVideosSection } from "../components/FreeResources/FreeVideosSection/FreeVideosSection";
import { HelpfulContentSection } from "../components/FreeResources/HelpfulContentSection/HelpfulContentSection";
import { OrientedOnSolutionsSection } from "../components/FreeResources/OrientedOnSolutionsSection/OrientedOnSolutionsSection";
import { BlogSection } from "../components/Home/BlogSection/BlogSection";
import { TopHeader } from "../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../components/Shared/Layout/Layout";

const FreeResourcesPage = () => (
  <Layout>
    <TopHeader title="Obsah zadarmo" />
    <HelpfulContentSection />
    {/* <FreeResourcesButtonsWithTextSection /> */}
    <FreeVideosSection />
    <BlogSection />
    <OrientedOnSolutionsSection />
  </Layout>
);

export default FreeResourcesPage;
